var $class="se2--content-interactive-graphic",$name="ContentInteractiveGraphic",$path="app/components/ContentInteractiveGraphic/index.js",$this={$class,$name,$path,};import lazyHandleOpenModalButtons from 'app/components/utilities/lazyHandleOpenModalButtons';

import shell from 'app/modules/shell';

import 'app/partials/image';

import capArrayIndex from 'app/utilities/capArrayIndex';
import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import parseDecimal from 'app/utilities/parseDecimal';

import { breakpoints, css, directions, gsapAnimation, keyNames } from 'configs';

import gsap from 'gsap';
import Draggable from 'gsap/Draggable';

import first from 'lodash/first';
import forEach from 'lodash/forEach';
import last from 'lodash/last';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import reduce from 'lodash/reduce';

export const cardSelector = '.card';
export const cardsWrapperSelector = '.cards-wrapper';
export const controlsWrapperClass = 'controls';
export const descriptionContainerSelector = '.description-container';
export const hotspotLabelSelector = '.hotspot-label';
export const hotspotSelector = '.map-hotspot';
export const imageElementSelector = 'img';
export const imageWrapperSelector = '.image-wrapper';
export const linksWrapperSelector = '.buttons-wrapper';
export const linksSelector = '.se2-link';
export const mirrorClassName = 'mirror';
export const nextButtonSelector = '.swiper-button-next';
export const previousButtonSelector = '.swiper-button-prev';
export const swiperContainerSelector = '.swiper-container';
const cardDescriptionSelector = '.card-description';

const slideDuration = 0.3;
const getDelaultLabels = (slideElements) => map(slideElements, ({ ariaLabel }) => ariaLabel);
const getDescriptionLabel = (slide, slideNumber, defaultLabels) => `${defaultLabels[slideNumber]}
    ${slide.querySelector(cardDescriptionSelector).innerHTML.trim()}`;

const onFocusIn = ({ target }) => {
  if (target.classList.contains('card')) {
    target.querySelector(linksWrapperSelector).setAttribute('aria-hidden', true);
  }
  if (target.classList.contains(css.classNames.button)) {
    target.closest(linksWrapperSelector).setAttribute('aria-hidden', false);
  }
};

const initSlider = (addEventListener, element, settings) => {
  const cardElement = element.querySelector(`${swiperContainerSelector} ${cardsWrapperSelector}`);
  const slideElements = element.querySelectorAll(`${swiperContainerSelector} ${cardSelector}`);
  const previousButtonElement = element.querySelector(previousButtonSelector);
  const nextButtonElement = element.querySelector(nextButtonSelector);

  let activeSlide = 0;

  const getCardsPositions = () => {
    const getPosition = (wrapper, slide, rtl) => {
      const { right: wrapperRight, left: wrapperLeft, width: wrapperWidth } = wrapper.getBoundingClientRect();
      const { right: slideRight, left: slideLeft } = slide.getBoundingClientRect();

      if (rtl) return slideLeft > wrapperLeft ? 0 : slideLeft - wrapperLeft;

      return slideRight < wrapperRight ? 0 : slideLeft - wrapperRight + wrapperWidth;
    };

    const positions = map(slideElements,
      (slide) => getPosition(cardElement, slide, settings.direction === directions.rtl));

    return { positions };
  };
  let { positions } = getCardsPositions();

  addEventListener(window, 'resize', () => {
    ({ positions } = getCardsPositions());
  });

  const dragPositions = map(slideElements,
    (slide) => (
      settings.direction === directions.rtl
        ? slide.getBoundingClientRect().right - cardElement.getBoundingClientRect().right
        : slide.offsetLeft - cardElement.offsetLeft
    ));

  const animateSlideSelection = () => {
    const { left: slideLeft, right: slideRight } = slideElements[activeSlide]?.getBoundingClientRect() ?? {};
    const { left: wrapperLeft, right: wrapperRight } = cardElement.getBoundingClientRect();

    if (slideLeft < wrapperLeft) {
      gsap.to(slideElements, {
        x: -positions[activeSlide],
        duration: slideDuration,
      });
    }
    if (slideRight > wrapperRight) {
      gsap.to(slideElements, {
        x: -positions[activeSlide],
        duration: slideDuration,
      });
    }
  };

  const animateSlideDrag = () => {
    gsap.to(slideElements, {
      x: -dragPositions[activeSlide],
      duration: slideDuration,
    });
  };

  const selectSlide = (i) => {
    activeSlide = capArrayIndex(i, slideElements);
  };

  selectSlide(0);
  animateSlideSelection();

  addEventListener(previousButtonElement, 'click', () => {
    if (activeSlide === 0) return;
    selectSlide(activeSlide - 1);
    animateSlideSelection();
  });

  addEventListener(nextButtonElement, 'click', () => {
    if (activeSlide === slideElements.length - 1) return;
    selectSlide(activeSlide + 1);
    animateSlideSelection();
  });

  const initKeyboardNavigation = (hotspots) => {
    forEach(hotspots, (hotspot, hotspotNumber) => {
      const onSelect = (number) => {
        const nextHotspot = capArrayIndex(number, hotspots);
        selectSlide(nextHotspot);
        animateSlideSelection();
        setTimeout(() => hotspots[nextHotspot]?.focus(), slideDuration + 200);
      };

      const onKeyDown = (e) => {
        const slideLinkElements = Array.from(hotspot.querySelectorAll(linksSelector));
        let activeIndex;

        const next = hotspotNumber + 1;
        const prev = hotspotNumber - 1;
        switch (e.code) {
          case keyNames.tab:
            if (next >= hotspots.length || prev === -1) break;
            e.preventDefault();
            onSelect(e.shiftKey ? prev : next);
            break;
          case keyNames.arrowLeft:
            onSelect(prev);
            break;
          case keyNames.arrowRight:
            onSelect(next);
            break;
          case keyNames.arrowDown:
            e.preventDefault();
            activeIndex = slideLinkElements.indexOf(document.activeElement);
            if (activeIndex + 1 === slideLinkElements.length) first(slideLinkElements).focus();
            else slideLinkElements[activeIndex + 1].focus();
            break;
          case keyNames.arrowUp:
            e.preventDefault();
            activeIndex = slideLinkElements.indexOf(document.activeElement);
            if (activeIndex === 0) last(slideLinkElements).focus();
            else slideLinkElements[activeIndex - 1].focus();
            break;
          default:
            break;
        }
      };

      addEventListener(hotspot, 'keydown', onKeyDown);
    });
  };

  initKeyboardNavigation(slideElements);

  const phantomElement = document.createElement('div');
  let dragStart = 0;

  const onDragStart = (event) => {
    dragStart = event.clientX;
  };

  const onDragEnd = (event) => {
    const offset = settings.directions === directions.rtl
      ? event.clientX - dragStart
      : dragStart - event.clientX;

    if (offset < 0 && activeSlide !== 0) {
      selectSlide(activeSlide - 1);
    } else if (offset > 0
      && cardElement.scrollWidth - cardElement.clientWidth > 0
      && activeSlide !== slideElements.length - 1) {
      selectSlide(activeSlide + 1);
    }
    animateSlideDrag();
  };

  const onClick = (event) => {
    if (event.button === 1) {
      event.preventDefault();
    }
  };

  return {
    draggable: new Draggable(phantomElement, {
      type: 'x',
      // according to the https://www.researchgate.net average swipe distance on mobile is 18% from screen width
      minimumMovement: window.innerWidth * gsapAnimation.recognitionRate,
      throwProps: true,
      allowContextMenu: true,
      cursor: 'default',
      activeCursor: 'grabbing',
      trigger: element.querySelector(swiperContainerSelector),
      onDragStart,
      onDragEnd,
      onClick,
    }),
  };
};

const initHotspots = (addEventListener, element) => {
  const slideElements = element.querySelectorAll(`${imageWrapperSelector} ${cardSelector}`);
  const defaultLabels = getDelaultLabels(slideElements);
  let isSlideOpen = false;

  let activeSlide = -1;
  const hotspotElements = element.querySelectorAll(hotspotSelector);

  const selectSlide = (i) => {
    const previousSlide = activeSlide;
    activeSlide = capArrayIndex(i, slideElements);
    activeSlide = previousSlide === activeSlide ? -1 : activeSlide;

    forEach(slideElements, (slide, slideNumber) => {
      slide.ariaLabel = slide.ariaLabel === defaultLabels[slideNumber] && activeSlide === slideNumber
        ? getDescriptionLabel(slide, slideNumber, defaultLabels)
        : defaultLabels[slideNumber];
      slide.setAttribute('aria-expanded', activeSlide === slideNumber ? !isSlideOpen : isSlideOpen);
      if (slideNumber === activeSlide) slide.focus();
      slide.querySelector(`${descriptionContainerSelector}`)
        .classList
        .toggle(css.classNames.hide, activeSlide !== slideNumber);
    });
    forEach(hotspotElements, (hotspot, hotspotNumber) => {
      hotspot.classList.toggle(css.classNames.active, hotspotNumber === activeSlide);
    });
  };

  addEventListener(window, 'click', () => {
    if (document.activeElement === document.querySelector(`.${css.classNames.sdlBody}`)) {
      forEach(hotspotElements, (hotspot) => {
        hotspot.classList.remove(css.classNames.active);
      });
      forEach(slideElements, (slide, slideNumber) => {
        slide.ariaLabel = defaultLabels[slideNumber];
        slide.setAttribute('aria-expanded', isSlideOpen);
        slide.querySelector(`${descriptionContainerSelector}`)
          .classList
          .add(css.classNames.hide);
      });
    }
  });

  forEach(hotspotElements, (hotspot, i) => {
    const descriptionContainerElement = hotspot.querySelector(descriptionContainerSelector);
    descriptionContainerElement.classList.add(css.classNames.hide);

    addEventListener(hotspot, 'click', () => {
      selectSlide(i);
    });
  });

  const initKeyboardNavigation = (hotspots) => {
    forEach(hotspots, (hotspot, hotspotNumber) => {
      const onNavigation = (number) => {
        const nextHotspot = capArrayIndex(number, hotspots);
        hotspots[nextHotspot].querySelector(cardSelector).focus();
      };

      const onKeyDown = (e) => {
        const slideLinkElements = Array.from(hotspot.querySelectorAll(linksSelector));
        const activeIndex = slideLinkElements.indexOf(document.activeElement);

        const next = hotspotNumber + 1;
        const prev = hotspotNumber - 1;
        const toggleSlide = () => {
          e.preventDefault();
          isSlideOpen = !isSlideOpen;
          selectSlide(hotspotNumber);
        };
        switch (e.code) {
          case keyNames.enter:
            if (isSlideOpen && activeIndex !== -1) {
              // setTimeout is needed to keep focus on the current card after opening a new window
              setTimeout(() => {
                isSlideOpen = !isSlideOpen;
                slideLinkElements[activeIndex].focus();
              });
              return;
            }
            toggleSlide();
            break;
          case keyNames.space:
            toggleSlide();
            break;
          case keyNames.tab:
            if (!isMobileBreakpoint() && isSlideOpen) {
              e.preventDefault();
              if (e.shiftKey) {
                activeIndex === 0
                  ? last(slideLinkElements).focus()
                  : slideLinkElements[activeIndex - 1].focus();
              } else {
                activeIndex + 1 === slideLinkElements.length
                  ? first(slideLinkElements).focus()
                  : slideLinkElements[activeIndex + 1].focus();
              }
            }
            break;
          case keyNames.arrowRight:
            e.preventDefault();
            if (!isMobileBreakpoint() && isSlideOpen) {
              toggleSlide();
            }
            onNavigation(next);
            break;
          case keyNames.arrowLeft:
            e.preventDefault();
            if (!isMobileBreakpoint() && isSlideOpen) {
              toggleSlide();
            }
            onNavigation(prev);
            break;
          case keyNames.arrowDown:
            e.preventDefault();
            if (activeIndex + 1 === slideLinkElements.length) first(slideLinkElements).focus();
            else slideLinkElements[activeIndex + 1].focus();
            break;
          case keyNames.arrowUp:
            e.preventDefault();
            if (activeIndex === 0) last(slideLinkElements).focus();
            else slideLinkElements[activeIndex - 1].focus();
            break;
          default:
            break;
        }
      };

      addEventListener(hotspot, 'keydown', onKeyDown);
    });
  };

  initKeyboardNavigation(hotspotElements);
};

const calculateHotspotLocations = (element) => {
  const hotspotElements = element.querySelectorAll(hotspotSelector);
  const imageElementWidth = element.querySelector(imageElementSelector)?.clientWidth;
  const scaleCoeff = window.innerWidth / breakpoints.xl.min;
  const halfScreenVariable = 0.5;

  forEach(orderBy(hotspotElements, (hotspotElement) => hotspotElement.dataset.y, 'desc'),
    (hotspot, index) => {
      const xPositionOfImage = parseDecimal(hotspot.dataset.x);
      const yPositionOfImage = parseDecimal(hotspot.dataset.y);

      const x = Math.round(xPositionOfImage * scaleCoeff);
      const y = Math.round(yPositionOfImage * scaleCoeff);

      // don't use logical styles here because hotspot location doesn't change depending on rtl or ltr direction
      hotspot.style.top = `${y}px`;
      hotspot.style.left = `${x}px`;
      hotspot.style.zIndex = index;

      hotspot.classList.toggle(mirrorClassName, (x / imageElementWidth) > halfScreenVariable);
    });
};

export default shell.registerComponent($this, ({
  addEventListener, configs, mount, settings,
}) => {
  let isShowedControls = false;
  const showControls = (element) => {
    if (isMobileBreakpoint()) {
      const slideElements = element.querySelectorAll(`${swiperContainerSelector} ${cardSelector}`);
      const controlsElement = element.querySelector(`.${controlsWrapperClass}`);
      const defaultLabels = getDelaultLabels(slideElements);

      !isShowedControls && forEach(slideElements, (slide, slideNumber) => {
        slide.setAttribute('role', 'none');
        slide.ariaLabel = getDescriptionLabel(slide, slideNumber, defaultLabels);
      });
      const cardElementsWidth = reduce(slideElements, (sum, card) => sum + card.offsetWidth, 0);

      controlsElement.classList.toggle(configs.css.classNames.hide, cardElementsWidth <= element.offsetWidth);
      isShowedControls = true;
    }
  };

  mount((element) => {
    const cardsWrapper = element.querySelector(cardsWrapperSelector);
    addEventListener(cardsWrapper, 'focusin', onFocusIn);
    showControls(element);

    initSlider(addEventListener, element, settings);
    calculateHotspotLocations(element);
    initHotspots(addEventListener, element);

    const onResize = () => {
      calculateHotspotLocations(element);
      showControls(element);
    };

    addEventListener(window, 'resize', onResize);

    lazyHandleOpenModalButtons(addEventListener, element);
  });
});
